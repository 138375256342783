/* eslint-disable object-curly-newline */
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

import { useNavigate, useLocation } from 'react-router';
import styled from 'styled-components';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';

import {
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Autocomplete,
} from '@mui/material';
import { CREATE_NEW_USER } from '../mutations';
import { setPaymentForm, setUserId } from '../actions/paymentActions';

import { Button } from '../components/Button';
import { Anchor } from '../components/Text';

const FormBackGround = styled.div`
  background-color: lightgrey;
  height: calc(100vh - 175px);
  display: grid;
  place-items: center;
  padding: 20px;
  overflow: scroll;
  h3 {
    text-align: center;
    font-size: 1.5rem;
    padding-top: 20px;
    color: ${(props) => props.theme.colors.blue};
  }
  iframe {
    width: 100%;
    height: 100vh;
  }
`;

const SmallText = styled.p`
  width: 50%;
  color: red;
  font-size: bold;
  flex 1 0 ${(props) => props?.width || '100%'};
  text-align: center;
  
`;

const RegisterForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 30px;
  margin-top: 30px;
`;
const FormField = styled(TextField)`
  flex 1 0 ${(props) => props?.width || '100%'};

`;

const FormCard = styled(Card)`
  width: ${(props) => (props.usingmobile ? '90%' : '60%')};
  height: calc(100vh - 220px);
  overflow: scroll;
`;

const FormDob = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex 1 0 ${(props) => props?.width || '100%'};
  place-items: center;

`;

const FormAutoComplete = styled(Autocomplete)`
flex 1 0 ${(props) => props?.width || '100%'};
`;

const FormControlRadio = styled(FormControl)`
flex 1 0 ${(props) => props?.width || '100%'};
place-items: center;

`;

function RegistrationForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usingMobile = useSelector(({ mobile }) => mobile.mobile);
  const referalLink = useSelector(({ payment }) => payment.referallink);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
  };

  const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
      countDownDate - new Date().getTime(),
    );

    useEffect(() => {
      const interval = setInterval(() => {
        setCountDown(countDownDate - new Date().getTime());
      }, 1000);

      return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
  };

  // const apiUrl = process.env.REACT_APP_API_URL;

  const formRef = useRef();

  const tShirtSizes = ['L - 42/44in', 'XL - 46/46in'];

  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    dob: '',
    address: '',
    postcode: '',
    telephonenumber: '',
    gender: '',
    ageonraceday: '',
    shirtsize: tShirtSizes[0],
    signature: '',
    clubname: '',
    registrationnumber: '',
  });

  const handleChange = (event) => {
    const { name } = event.target;
    let { value } = event.target;

    if (name === 'ageonraceday') {
      value = Number(event.target.value);
    }

    setForm({ ...form, [name]: value });
  };

  const [createUser, { loading, error }] = useMutation(CREATE_NEW_USER);

  // eslint-disable-next-line consistent-return
  const handleSubmit = async (event) => {
    try {
      form.signature = `${form.firstname} ${form.lastname} `;
      form.referal = referalLink;
      if (formRef.current.reportValidity()) {
        event.preventDefault();
        // await getUser({variables: {input: {form.email}}})
        // const fetchUser = await refetch({ input: { email: form.email } });
        dispatch(setPaymentForm({ form }));
        const createdUser = await createUser({
          variables: { input: { ...form } },
        });

        dispatch(setUserId(createdUser?.data?.CreateNewUser?.userId));

        if (error) {
          return 'Error';
        }
        if (loading) {
          return 'Loading';
        }

        setForm({
          firstname: '',
          lastname: '',
          email: '',
          dob: '',
          address: '',
          postcode: '',
          telephonenumber: '',
          gender: '',
          ageonraceday: '',
          shirtsize: '',
          clubmember: false,
          signature: '',
        });
        navigate('/checkout');
      }
    } catch (err) {
      return new Error(err);
    }
  };
  const trailRunDate = new Date('10/13/2024 5:0 AM');
  const [days, hours, minutes, seconds] = useCountdown(trailRunDate);

  // if (days + hours + minutes + seconds <= 0) {
  return (
    <FormBackGround>
      <Card
        className="container80"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
          paddingBottom: '1rem',
        }}
      >
        <h3>
          Coxhoe Trail Run 2024 Registrations Closed We are exited to see you
          all in {days}d:{hours}h:{minutes}m:{seconds}s
        </h3>
        {/* <Button href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRufgDsZ7uVNtqe7DYkUo7RHxEVse1Ti26gUZ9Fl3Pg_bvKotpJZkIl_xka7b-AydRY3HzXnVYaOc_P/pubhtml?gid=519280261&single=true">
            Click Here To See Results
          </Button> */}
      </Card>
    </FormBackGround>
  );
  // }
  // return (
  //   <FormBackGround>
  //     <FormCard raised usingmobile={usingMobile}>
  //       <h3>Coxhoe Trail Run Registration</h3>
  //       <h3>
  //         Registration Close In {days}d:{hours}h:{minutes}m:{seconds}s
  //       </h3>

  //       <RegisterForm ref={formRef} onSubmit={handleSubmit}>
  //         <FormField
  //           required
  //           id="outlined-error"
  //           label="FirstName"
  //           variant="outlined"
  //           className="width50"
  //           onChange={handleChange}
  //           value={form.firstname}
  //           name="firstname"
  //           type="text"
  //           width={usingMobile ? '50%' : '39%'}
  //           autoComplete="given-name"
  //         />
  //         <FormField
  //           required
  //           id="outlined-default"
  //           label="LastName"
  //           variant="outlined"
  //           className="width50"
  //           onChange={handleChange}
  //           name="lastname"
  //           value={form.lastname}
  //           type="text"
  //           width={usingMobile ? '50%' : '39%'}
  //           autoComplete="family-name"
  //         />
  //         <FormField
  //           required
  //           id="outlined-error"
  //           label="Email"
  //           variant="outlined"
  //           className="width50"
  //           onChange={handleChange}
  //           name="email"
  //           value={form.email}
  //           type="email"
  //           width={usingMobile ? '50%' : '39%'}
  //           autoComplete="email"
  //         />
  //         <FormField
  //           required
  //           id="outlined-default"
  //           label="Telephone Number"
  //           variant="outlined"
  //           type="text"
  //           name="telephonenumber"
  //           onChange={handleChange}
  //           value={form.telephonenumber}
  //           width={usingMobile ? '50%' : '39%'}
  //           autoComplete="tel"
  //         />
  //         <FormDob width={usingMobile ? '50%' : '39%'}>
  //           <FormLabel>DOB</FormLabel>
  //           <FormField
  //             required
  //             name="dob"
  //             onChange={handleChange}
  //             type="date"
  //             value={form.dob}
  //             width="20%"
  //             autoComplete="bday"
  //           />
  //         </FormDob>
  //         <FormField
  //           required
  //           id="outlined-default"
  //           label="Age On Race Day"
  //           variant="outlined"
  //           type="number"
  //           name="ageonraceday"
  //           value={form.ageonraceday}
  //           onChange={handleChange}
  //           width={usingMobile ? '50%' : '39%'}
  //         />
  //         <FormField
  //           required
  //           id="outlined-default"
  //           label="Address"
  //           variant="outlined"
  //           type="text"
  //           name="address"
  //           onChange={handleChange}
  //           value={form.address}
  //           width={usingMobile ? '50%' : '39%'}
  //           autoComplete="street-address"
  //         />
  //         <FormField
  //           required
  //           id="outlined-default"
  //           label="PostCode"
  //           variant="outlined"
  //           type="text"
  //           name="postcode"
  //           onChange={handleChange}
  //           value={form.postcode}
  //           width={usingMobile ? '50%' : '39%'}
  //           autoComplete="postal-code"
  //         />
  //         <FormAutoComplete
  //           options={tShirtSizes}
  //           disablePortal
  //           id="combo-box-demo"
  //           value={form.shirtsize}
  //           className="width50"
  //           onChange={(event, newValue) => {
  //             setForm({ ...form, shirtsize: newValue });
  //           }}
  //           renderInput={(params) => (
  //             <TextField {...params} label="T-shirt Size" name="shirtsize" />
  //           )}
  //           width={usingMobile ? '50%' : '20%'}
  //         />

  //         <FormControlRadio
  //           component="fieldset"
  //           width={usingMobile ? '50%' : '20%'}
  //         >
  //           <FormLabel
  //             component="legend"
  //             style={{ marginLeft: 'auto', marginRight: 'auto' }}
  //           >
  //             Gender
  //           </FormLabel>
  //           <RadioGroup
  //             row
  //             aria-label="gender"
  //             name="row-radio-buttons-group"
  //             value={form.gender}
  //           >
  //             <FormControlLabel
  //               value="female"
  //               name="gender"
  //               control={<Radio />}
  //               label="Female"
  //               onChange={handleChange}
  //             />
  //             <FormControlLabel
  //               value="male"
  //               name="gender"
  //               control={<Radio />}
  //               label="Male"
  //               onChange={handleChange}
  //             />
  //           </RadioGroup>
  //         </FormControlRadio>

  //         <FormControlRadio
  //           component="fieldset"
  //           width={usingMobile ? '50%' : '20%'}
  //         >
  //           <FormLabel
  //             component="legend"
  //             style={{ marginLeft: 'auto', marginRight: 'auto' }}
  //           >
  //             Are You A Club Member?
  //           </FormLabel>
  //           <RadioGroup
  //             row
  //             aria-label="clubmembercheck"
  //             name="row-radio-buttons-group"
  //             value={form.clubmember}
  //           >
  //             <FormControlLabel
  //               value="Yes"
  //               name="clubmember"
  //               control={<Radio />}
  //               label="Yes"
  //               onChange={handleChange}
  //             />
  //             <FormControlLabel
  //               value="No"
  //               name="clubmember"
  //               control={<Radio />}
  //               label="No"
  //               onChange={handleChange}
  //             />
  //           </RadioGroup>
  //         </FormControlRadio>
  //         {form.clubmember === 'Yes' && (
  //           <>
  //             <FormField
  //               required
  //               id="outlined-default"
  //               label="Club Name"
  //               variant="outlined"
  //               type="text"
  //               name="clubname"
  //               onChange={handleChange}
  //               value={form.clubname}
  //               width={usingMobile ? '50%' : '39%'}
  //             />
  //             <FormField
  //               required
  //               id="outlined-default"
  //               label="Registration Number"
  //               variant="outlined"
  //               type="text"
  //               name="registrationnumber"
  //               onChange={handleChange}
  //               value={form.registrationnumber}
  //               width={usingMobile ? '50%' : '39%'}
  //             />
  //           </>
  //         )}
  //         <SmallText>
  //           Please be aware that T-shirt sizes for on-the-day registrations
  //           cannot be guaranteed. By participating, I understand and accept that
  //           I am doing so entirely at my own risk. The organizers will not be
  //           held responsible for any loss or injury, regardless of cause, that
  //           may occur during or as a result of my participation in the race. By
  //           participating, I also acknowledge that I have read and accepted the{' '}
  //           <Anchor href="/privacy" target="_blank">
  //             privacy policy
  //           </Anchor>
  //         </SmallText>
  //         {!loading ? (
  //           <Button
  //             onClick={handleSubmit}
  //             style={{ marginLeft: 'auto', marginRight: 'auto' }}
  //           >
  //             Submit
  //           </Button>
  //         ) : (
  //           <CircularProgress />
  //         )}
  //       </RegisterForm>
  //     </FormCard>
  //   </FormBackGround>
  // );
}

export default RegistrationForm;
